import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Row, Col } from "react-bootstrap"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import ConnectSidebar from "../../components/layout/connect-sidebar"
import Iframe from "react-iframe"
import useGCLID from "../../hooks/useGCLID"

const CatalogPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Request a Catalog`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect">Connect with Us</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect/catalog">Request a Catalog</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar></ConnectSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Request a Catalog</h1>
            <p>
              Bring your ideal outdoor experiences to life and create amazing
              spaces for entertaining, relaxing, and growing together. The
              elegant designs and limitless customization possibilities of
              Walpole Outdoors let you fulfill your inspiration to completely
              transform your landscape, enhance your home and your yard, and
              make it all your own with the perfect finishing touches.
            </p>
            <hr />
            <Iframe
              className="catalogFrame"
              url={useGCLID("https://www.tfaforms.com/4865963", "tfa_67")}
              frameBorder="0"
            />
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CatalogPage
